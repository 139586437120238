import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';
import { Dayjs } from 'dayjs';

// Define the shape of your context state
interface TransactionContextType {
  transactionId: number | null;
  setTransactionId: (id: number | null) => void;
}

// Create the context with a default value
const TransactionContext = createContext<TransactionContextType>({
  transactionId: null,
  setTransactionId: (id) => {
    console.log('Default context: Transaction ID received', id);
  },
});

interface TransactionIdProviderProps {
  children: ReactNode;
}

export const TransactionIdProvider = ({ children }: TransactionIdProviderProps) => {
  const [transactionId, setTransactionIdState] = useState<number | null>(() => {
    const stored = localStorage.getItem('transactionId');

    return stored ? Number(stored) : null;
  });

  const setTransactionId = useCallback((id: number | null) => {
    console.log('Transaction ID received:', id);
    setTransactionIdState(id);
    if (id) {
      localStorage.setItem('transactionId', id.toString());
    } else {
      localStorage.removeItem('transactionId');
    }
  }, []);

  return (
    <TransactionContext.Provider value={{ transactionId, setTransactionId }}>
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  
  return useContext(TransactionContext);
};
