import axios from 'axios';
import React, {
  MutableRefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAuth } from './AuthProvider';
import { useEssentialDataContext } from './EssentialDataContext';
import useServer from 'src/utils/useServer';

let TOKEN = localStorage.getItem('token') || '';

interface WebSocketContextType {
  socket: WebSocket | null;
  disconnect: () => void;
  devicesRunning: number;
  devicesIdle: number;
  devicesOffline: number;
  notificationReceive: any;
}

interface Devices {
  id: number;
  groupId: number;
  name: string;
  uniqueId: string;
  phone: string;
  attributes: {
    sim_provider?: string;
  };
  lastUpdate: string;
  positionId: number;
  category: string;
  status: string;
  speed: number;
}

interface EssentialData {
  groups: any[];
  devices: Devices[];
  geofences: any[];
}

interface FetchedDevice {
  id: number;
  groupId: number;
  name: string;
  uniqueId: string;
  phone: string;
  attributes: {
    sim_provider?: string;
  };
  lastUpdate: string;
  positionId: number;
  category: string;
  status: string;
  speed: number;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }

  return context;
};

interface WebSocketProviderProps {
  children: React.ReactNode;
}

let execute_once = 0;

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const SERVER = useServer();
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { isAuthenticated } = useAuth();
  const { essentialData, refreshData } = useEssentialDataContext();
  const { groups, devices, geofences } = essentialData as EssentialData;
  const [devicesRunning, setDevicesRunning] = useState(0);
  const [devicesIdle, setDevicesIdle] = useState(0);
  const [devicesOffline, setDevicesOffline] = useState(0);
  const [notificationReceive, setNotificationReceive] = useState([]);

  const WS_URL = `https://${SERVER}`;

  useEffect(() => {
    TOKEN = localStorage.getItem('token') || '';

    if (isAuthenticated && WS_URL) {
      establishSession(TOKEN);
    } else {
      TOKEN = '';
      disconnect();
    }
  }, [isAuthenticated]);

  let filteredDevices = devices;

  filteredDevices = filteredDevices.filter(
    (device) => device.lastUpdate !== null && device.lastUpdate.trim() !== '',
  );

  const establishSession = async (TOKEN: string) => {
    try {
      axios.defaults.withCredentials = true; // Ensure axios handles cookies
      const serverRequest = await axios.get(WS_URL + '/api/server');
      const userRequest = await axios.get(WS_URL + `/api/session?token=${TOKEN}`, {
        withCredentials: true, // Include credentials in the request
      });

      console.log(SERVER);

      const newSocket = new WebSocket('wss://' + SERVER + '/api/socket');
      setSocket(newSocket);

      newSocket.onclose = (event) => {
        console.log('WebSocket closed. Attempting to reconnect...');
        setTimeout(() => establishSession(TOKEN), 3000); // Try to reconnect after 3 seconds
      };

      // newSocket.onopen = () => {
      //   console.log('socket Open');
      // };

      newSocket.onmessage = async (event) => {
        // console.log('Devices', filteredDevices);

        if (filteredDevices.length == 0) {
          const tokenRefresh = localStorage.getItem('token') || '';
          await updateDeviceHolder(tokenRefresh);
          console.info('Device updated successfully');
        }
        const data = JSON.parse(event.data);

        if (execute_once > 0 && data.devices !== undefined) {
          const deviceToUpdate = data.devices[0];
          const deviceIndex = filteredDevices.findIndex((device) => device.id == deviceToUpdate.id);

          // console.log('deviceIndex', deviceIndex);

          if (deviceIndex !== -1) {
            filteredDevices[deviceIndex].lastUpdate = deviceToUpdate.lastUpdate;
            filteredDevices[deviceIndex].status = deviceToUpdate.status;
            updateDeviceStatusCounts('execute_once', filteredDevices);
          }
        }

        if (data.positions) {
          const deviceToUpdate = data.positions[0];
          const deviceIndex = filteredDevices.findIndex(
            (device) => device.id == deviceToUpdate.deviceId,
          );

          if (execute_once === 0) {
            const wsDevices = data.positions;
            wsDevices.forEach((wsDevice: any) => {
              const deviceIndex = filteredDevices.findIndex(
                (deviceX) => deviceX.id === wsDevice.deviceId,
              );
              if (deviceIndex !== -1) {
                filteredDevices[deviceIndex] = {
                  ...filteredDevices[deviceIndex],
                  speed: wsDevice.speed,
                };
              }
            });
            execute_once++;
          }
          if (deviceIndex !== -1) {
            filteredDevices[deviceIndex].speed = deviceToUpdate.speed;
            updateDeviceStatusCounts('positions', filteredDevices);
          }
        }

        if (data.events) {
          // console.error('Event: ', data.events[0]);
          const tokenRefresh = localStorage.getItem('token') || '';
          await updateDeviceHolder(tokenRefresh);
          setNotificationReceive(data.events[0]);
        }
      };

      return () => {
        newSocket.close();
      };
    } catch (error) {
      console.error('Error establishing session:', error);
      setTimeout(() => establishSession(TOKEN), 3000); // Try to reconnect after 3 seconds if there's an error
    }
  };

  const disconnect = () => {
    if (socket) {
      socket.close();
      console.info('Websocket disconnected');
    }
  };

  const updateDeviceStatusCounts = async (from: string, devicesX: Devices[]) => {
    try {
      const runningCount = devicesX.filter(
        (device) => device.status === 'online' && device.speed != 0,
      ).length;
      const idleCount = devicesX.filter(
        (device) => device.status === 'online' && device.speed <= 0,
      ).length;
      const offlineCount = devicesX.filter(
        (device) => device.status === 'offline' || device.status === 'unknown',
      ).length;

      // devicesRunningRef.current = runningCount;
      // devicesIdleRef.current = idleCount;
      // devicesOfflineRef.current = offlineCount;

      // console.log('Updated counts:', {
      //   from: from,
      //   running: runningCount,
      //   idle: idleCount,
      //   offline: offlineCount,
      // });

      setDevicesRunning(runningCount);
      setDevicesIdle(idleCount);
      setDevicesOffline(offlineCount);
    } catch (error) {
      console.error('Error updating device status counts:', error);
    }
  };

  const fetchDevices = async (tokenRefresh: string) => {
    TOKEN = localStorage.getItem('token') || '';

    try {
      if (!SERVER) {
        console.error('Username, password, or server not found in session storage');

        return;
      }

      const options = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${tokenRefresh}`,
        },
      };

      const response = await fetch(`https://${SERVER}/api/devices`, options);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      let responseData = await response.json();
      responseData = responseData.filter(
        (device: any) => device.lastUpdate !== null && device.lastUpdate.trim() !== '',
      );

      return responseData;
    } catch (error) {
      console.error('Error fetching devices data:', error);
    }
  };

  const updateDeviceHolder = async (tokenRefresh: string) => {
    try {
      const fetchedDevices: FetchedDevice[] = await fetchDevices(tokenRefresh);

      if (!fetchedDevices) return; // Return if no data fetched

      fetchedDevices.forEach((fetchedDevice: FetchedDevice) => {
        const existingDeviceIndex = filteredDevices.findIndex(
          (device) => device.id === fetchedDevice.id,
        );
        if (existingDeviceIndex !== -1) {
          filteredDevices[existingDeviceIndex].lastUpdate = fetchedDevice.lastUpdate;
          filteredDevices[existingDeviceIndex].status = fetchedDevice.status;
        } else {
          filteredDevices.push(fetchedDevice);
        }
      });

      updateDeviceStatusCounts('updateDeviceHolder', filteredDevices);
    } catch (error) {
      console.error('Error updating filteredDevices:', error);
    }
  };

  const contextValue: WebSocketContextType = {
    socket,
    disconnect,
    devicesRunning,
    devicesIdle,
    devicesOffline,
    notificationReceive,
  };

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};
