import React, { useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert,
  AlertTitle,
  Autocomplete,
  TextField,
} from '@mui/material';
import BlankCard from 'src/components/shared/BlankCard';
import { Button as ButtonAnt, ConfigProvider, Space, Table, theme } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { FileExcelFilled, FileExcelTwoTone } from '@ant-design/icons';
import { IconPencil, IconTrash } from '@tabler/icons';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { AppState, useSelector } from 'src/store/Store';
import DashboardCard from 'src/components/shared/DashboardCard';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import useServer from 'src/utils/useServer';

type Props = {};

interface Devices {
  id: number;
  groupId: number;
  name: string;
  uniqueId: string;
  phone: string;
  attributes: {
    sim_provider?: string; // Assuming attributes is a map of key-value pairs where the keys are strings and values are strings or null
  };
  lastUpdate: string;
  positionId: number;
  category: string;
}

interface Group {
  id: number;
  name: string;
}

interface EssentialData {
  groups: any[]; // Assuming the type of groups
  devices: Devices[]; // Array of devices of type Devices
  geofences: any[]; // Assuming the type of geofences
}

interface VehicleCategory {
  id: number;
  name: string;
  number: string;
  department: string;
}

type OnChange = NonNullable<TableProps<VehicleCategory>['onChange']>;
type Filters = Parameters<OnChange>[1];

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const validationSchema = yup.object({
  smsContactName: yup.string().required('Name is required'),
  smsNumber: yup
    .number()
    .min(11, 'Number should be of minimum 11 characters length')
    .required('Number is required'),
  smsDepartment: yup.string().required('Group is required'),
});

function Numbers({}: Props) {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark');

  const { defaultAlgorithm, darkAlgorithm } = theme;
  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const formik = useFormik({
    initialValues: {
      id: null,
      smsContactName: '',
      smsNumber: '',
      smsDepartment: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const smsNumberData = {
          id: isEditing ? values.id : 0,
          name: values.smsContactName,
          number: parseInt(values.smsNumber),
          department: values.smsDepartment,
        };
        const url = `https://${numbers_node_server}/sms_gateway${isEditing ? `/${values.id}` : ''}`;
        const requestOptions = {
          method: isEditing ? 'PUT' : 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(smsNumberData),
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok) {
          throw new Error(`Failed to ${isEditing ? 'update' : 'add'} category`);
        }

        setSnackbarData({
          open: true,
          title: 'Success',
          message: `${isEditing ? 'Update' : 'Add'} action successful`,
          severity: 'success',
        });

        fetchSmsNumbers();
      } catch (error) {
        console.error('Error:', error);
        setSnackbarData({
          open: true,
          title: 'Error',
          message: `Failed to ${isEditing ? 'update' : 'add'} category`,
          severity: 'error',
        });
      }

      handleCloseSmsNumberModal();
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [openSmsNumberModal, setOpenDeviceModal] = useState(false);

  const [categoryId, setCategoryId] = useState(null);
  const [smsContactName, setContactName] = useState('');
  const [smsNumber, setCategoryLimit] = useState('');
  const [smsNumbers, setSmsNumbers] = useState<VehicleCategory[]>();

  const SERVER = useServer();
  let numbers_node_server = process.env.REACT_APP_NODEBACKEND;
  if(SERVER !== 'v2.rkcgps.net'){
    numbers_node_server = process.env.REACT_APP_NODEBACKEND_BACKUP;
  }

  const handleOpenDeviceModal = () => {
    setOpenDeviceModal(true);
  };

  const handleCloseSmsNumberModal = () => {
    setOpenDeviceModal(false);
  };

  const fetchSmsNumbers = async () => {
    try {
      const response = await fetch(`https://${numbers_node_server}/sms_gateway`);
      if (!response.ok) {
        throw new Error('Failed to fetch sms numbers');
      }
      const data = await response.json();
      setSmsNumbers(data.data);
    } catch (error) {
      console.error('Error fetching vehicle categories:', error);
    }
  };

  useEffect(() => {
    fetchSmsNumbers();
  }, []);

  // This code will execute immediately after the state update
  // console.log('smsNumbers:', smsNumbers);

  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const columns = [
    {
      title: 'Name.',
      dataIndex: 'name',
      width: 450,
    },
    {
      title: 'Number',
      dataIndex: 'number',
      width: 450,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 450,
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            variant="outlined"
            color="warning"
            startIcon={<IconPencil width={18} />}
            onClick={() => handleEditSmsNumber(record)}
            size="small"
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            startIcon={<IconTrash width={18} />}
            onClick={() => handleDeleteSmsNumber(record.id)}
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleChangeFilter: OnChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as Sorts);
  };

  const handleEditSmsNumber = (record: any) => {
    setContactName(record.name);
    formik.setValues({
      id: record.id,
      smsContactName: record.name,
      smsNumber: record.number.toString(),
      smsDepartment: record.department,
    });
    handleOpenDeviceModal(); // Open the modal
    setIsEditing(true); // Set editing mode to true
  };

  const handleAddNumber = () => {
    // Reset form fields or set default values
    formik.resetForm();
    setContactName('');
    setCategoryLimit('');

    setIsEditing(false); // Set editing mode to false
    handleOpenDeviceModal(); // Open the modal
  };

  const handleDeleteSmsNumber = (id: number) => {
    // Implement your delete logic here
    console.log('Delete action for record ID:', id);
  };

  const [snackbarData, setSnackbarData] = useState<{
    open: boolean;
    title: string;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    title: '',
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbarData({ ...snackbarData, open: false });
  };

  const { essentialData, refreshData } = useEssentialDataContext();
  const { groups, devices, geofences } = essentialData as EssentialData;

  const groupOptions = groups.map((group) => ({
    name: group.name,
    id: group.id,
  }));

  return (
    <DashboardCard title="Numbers">
      <>
        <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={handleAddNumber}>
          Add Number
        </Button>

        <ConfigProvider
          theme={{
            algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
          }}
        >
          <Table
            columns={columns}
            dataSource={smsNumbers}
            onChange={handleChangeFilter}
            rowKey={(record) => record.id}
          />
        </ConfigProvider>
        <Dialog open={openSmsNumberModal} onClose={handleCloseSmsNumberModal}>
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              {isEditing ? `Edit Number [${smsContactName}]` : 'Add Number'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>This category is used on device maintenance</DialogContentText>
              <Box mt={2}>
                <CustomTextField
                  autoFocus
                  margin="dense"
                  id="smsContactName"
                  name="smsContactName"
                  label="Name"
                  type="text"
                  fullWidth
                  value={formik.values.smsContactName}
                  error={formik.touched.smsContactName && Boolean(formik.errors.smsContactName)}
                  helperText={formik.touched.smsContactName && formik.errors.smsContactName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <CustomTextField
                  margin="dense"
                  id="smsNumber"
                  name="smsNumber"
                  label="Number"
                  type="text"
                  fullWidth
                  value={formik.values.smsNumber}
                  error={formik.touched.smsNumber && Boolean(formik.errors.smsNumber)}
                  helperText={formik.touched.smsNumber && formik.errors.smsNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                {/* <CustomTextField
                  margin="dense"
                  id="smsDepartment"
                  name="smsDepartment"
                  label="Department"
                  type="text"
                  fullWidth
                  value={formik.values.smsDepartment}
                  error={formik.touched.smsDepartment && Boolean(formik.errors.smsDepartment)}
                  helperText={formik.touched.smsDepartment && formik.errors.smsDepartment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                /> */}

                <Autocomplete
                  value={
                    groupOptions.find(
                      (group) => group.id === parseInt(formik.values.smsDepartment),
                    ) || null
                  }
                  onChange={(event: any, newValue: Group | null) => {
                    formik.setFieldValue('deviceGroup', newValue ? newValue.id : ''); // Update Formik field value
                    formik.setFieldTouched('deviceGroup', true, false); // Set field as touched
                  }}
                  id="group-select"
                  options={groupOptions}
                  getOptionLabel={(option: Group) => option.name}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Groups"
                      aria-label="Groups"
                      label="Groups"
                      error={formik.touched.smsDepartment && Boolean(formik.errors.smsDepartment)}
                      helperText={formik.touched.smsDepartment && formik.errors.smsDepartment}
                    />
                  )}
                  sx={{ mb: 2 }}
                />
              </Box>
            </DialogContent>
            <DialogActions sx={{ mb: 2, mr: 2 }}>
              <Button color="error" onClick={handleCloseSmsNumberModal}>
                Cancel
              </Button>
              <Button type="submit">{isEditing ? 'Update' : 'Add'}</Button>
            </DialogActions>
          </form>
        </Dialog>

        <React.Fragment>
          <Snackbar
            open={snackbarData.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarData.severity}
              variant="filled"
              sx={{ width: '100%', color: 'white' }}
            >
              <AlertTitle>{snackbarData.title}</AlertTitle>
              {snackbarData.message}
            </Alert>
          </Snackbar>
        </React.Fragment>
      </>
    </DashboardCard>
  );
}

export default Numbers;
