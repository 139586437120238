import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Stack,
  Box,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import DashboardCard from 'src/components/shared/DashboardCard';
import { AppState, useSelector } from 'src/store/Store';

type Props = {};

function Numbers({ }: Props) {
  const customizer = useSelector((state: AppState) => state.customizer);
  const currentTheme = customizer.activeMode;

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark');
  const [fileNames, setFileNames] = useState<string[]>(Array(9).fill(''));

  useEffect(() => {
    setIsDarkMode(currentTheme === 'dark');
  }, [currentTheme]);

  const handleFileChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedFileNames = [...fileNames];
      updatedFileNames[index] = file.name;
      setFileNames(updatedFileNames);
    }
  };

  return (
    <DashboardCard title="System Setup">
      <Stack spacing={4} alignItems="center" sx={{ width: '100%' }}>
        {/* Text inputs in one row, two columns */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="System Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              label="Header Title"
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Image input fields in four columns */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Header Favicon</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(0)}
                      />
                    </Button>
                    {fileNames[0] && (
                      <Typography variant="body2" noWrap>{fileNames[0]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Small - Light</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(1)}
                      />
                    </Button>
                    {fileNames[1] && (
                      <Typography variant="body2" noWrap>{fileNames[1]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Horizontal - light</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(2)}
                      />
                    </Button>
                    {fileNames[2] && (
                      <Typography variant="body2" noWrap>{fileNames[2]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Verical - light</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(3)}
                      />
                    </Button>
                    {fileNames[3] && (
                      <Typography variant="body2" noWrap>{fileNames[3]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Login Image (Index)</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(4)}
                      />
                    </Button>
                    {fileNames[4] && (
                      <Typography variant="body2" noWrap>{fileNames[4]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Small - Dark</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(5)}
                      />
                    </Button>
                    {fileNames[5] && (
                      <Typography variant="body2" noWrap>{fileNames[5]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Horizontal - Dark</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(6)}
                      />
                    </Button>
                    {fileNames[6] && (
                      <Typography variant="body2" noWrap>{fileNames[6]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Logo Vertical - Dark</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(7)}
                      />
                    </Button>
                    {fileNames[7] && (
                      <Typography variant="body2" noWrap>{fileNames[7]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} sx={{ padding: 2, border:'none', boxShadow:'none' }}>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6">Login Logo (Index)</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleFileChange(8)}
                      />
                    </Button>
                    {fileNames[8] && (
                      <Typography variant="body2" noWrap>{fileNames[8]}</Typography>
                    )}
                  </Stack>
                  <FormHelperText>Choose an image file to upload</FormHelperText>
                </Stack>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Button variant="contained" color="primary">
          Save Config
        </Button>
      </Stack>
    </DashboardCard>
  );
}

export default Numbers;
