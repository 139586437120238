import React from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import {
  AreaOfResponsibilityMarker,
  CompetitorMarker,
  MotorcycleMarker,
  PickUpMarker,
  ServiceMarker,
  StationMarker,
  TankerMarker,
  TruckerMarker,
} from './ImportMarkers';
import { AccountCircle, DriveEta, Memory, PlayCircleOutline } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { IconRoad } from '@tabler/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Libraries } from '@react-google-maps/api';

function knotsToKmh(speedInKnots: number): number {
  const speedInKmh = speedInKnots * 1.852;

  return Math.round(speedInKmh);
}
function formatDate(inputDateString: any) {
  // Convert string to Date object
  const inputDate = new Date(inputDateString);

  // Format date to desired format
  const options = { year: 'numeric', month: 'short', day: '2-digit' } as Intl.DateTimeFormatOptions;
  const datePart = inputDate.toLocaleDateString('en-US', options);

  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  } as Intl.DateTimeFormatOptions;
  const timePart = inputDate.toLocaleTimeString('en-US', timeOptions);

  return `${datePart} ${timePart}`;
}
const getTimeDifferenceText = (departureTime: any, arrivalTime: any) => {
  const departureDate = new Date(departureTime).getTime();
  const arrivalDate = new Date(arrivalTime).getTime();

  const timeDifference = arrivalDate - departureDate;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDifference / 1000) % 60);

  return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 || hours === 0 ? `${minutes}m ` : ''
    }${seconds}s`;
};

const CreateMarkerWithChip: React.FC<{ device: any }> = React.memo(({ device }) => {
  const status =
    device.status == 'offline' || device.status == 'unknown'
      ? 'Offline'
      : device.status == 'online' && knotsToKmh(device.speed) > 1
        ? 'Online'
        : 'Idle';

  return (
    <>
      <Chip
        style={{
          position: 'absolute',
          zIndex: 50,
          maxWidth: 'unset',
          height: '15px',
          fontWeight: 'bold',
        }}
        className={`${device.status == 'offline' || device.status == 'unknown'
          ? 'marker-labels-offline'
          : device.status == 'online' && knotsToKmh(device.speed) > 1
            ? 'marker-labels-active'
            : 'marker-labels-idle'
          }`}
        label={`${device.name} ${device.status == 'offline' || device.status == 'unknown' ? 0 : knotsToKmh(device.speed)
          }KPH`}
        size="small"
        color="primary"
      />
      <img
        style={{ zIndex: -1, transform: `translate(0px, 50%) rotate(${device.course}deg)` }}
        src={
          device.category == 'trolleybus'
            ? TankerMarker
            : device.category == 'pickup'
              ? PickUpMarker
              : device.category == 'motorcycle'
                ? MotorcycleMarker
                : device.category == 'truck'
                  ? TruckerMarker
                  : device.category == 'car'
                    ? ServiceMarker
                    : TankerMarker
        }
        alt=""
        height={device.category == 'trolleybus' ? 50 : 50}
        width={device.category == 'trolleybus' ? 50 : 50}
      />
    </>
  );
});
const CreateVehicleInfowindow: React.FC<{ position: any; device: any }> = React.memo(({ position, device }) => {
  let status = knotsToKmh(position.speed) > 0 ? 'Online' : 'Idle';
  let color = knotsToKmh(position.speed) > 0 ? '#15e01f' : '#e9821b';

  if (device.status) {
    status =
      device.status == 'offline' || device.status == 'unknown'
        ? 'Offline'
        : device.status == 'online' && knotsToKmh(device.speed) > 1
          ? 'Online'
          : 'Idle';
    color = status == 'Offline' ? '#fc1717' : status == 'Online' ? '#15e01f' : '#e9821b';
  }

  return (
    <Card
      className="infowindow-containers"
      sx={{ minWidth: '300px!important' }}
    >
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', p: 0, backgroundColor: 'grey.100' }}>
          <Box sx={{ alignSelf: 'center' }}>
            <CardContent
              sx={{
                p: 1,
                textAlign: 'center',
                '& .MuiTypography-root': {
                  lineHeight: 'normal',
                },
                '&:last-child': {
                  paddingBottom: 1,
                },
                width: '75px',
                height: '75px',
              }}
            >
              <Typography variant="h6">{knotsToKmh(position.speed)}</Typography>
              <Typography variant="body2">KM/H</Typography>
              <Typography variant="body2" sx={{ textTransform: 'uppercase' }}>
                {status}
              </Typography>
            </CardContent>
          </Box>
          <Box>
            <List
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                '& .MuiListItem-root': {
                  padding: 0,
                },
                '& .MuiListItemIcon-root': {
                  minWidth: 0,
                  marginRight: 1,
                },
                '& .MuiListItemText-root ': {
                  marginTop: 0,
                  marginBottom: 0,
                },
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <AccountCircle width={20} height={20} />
                </ListItemIcon>
                <ListItemText primary={device.driverName ? device.driverName : 'No Driver'} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Memory width={20} height={20} />
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'uppercase' }}
                  primary={status}
                  sx={{ color: color }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DriveEta width={20} height={20} />
                </ListItemIcon>
                <ListItemText primary={device ? device.name : 'No Driver'} />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Device Time: {formatDate(position.deviceTime)}</Typography>
      </Grid>

      {/* <Grid item xs={12}>
          <ButtonGroup variant="outlined" aria-label="outlined button group" sx={{ width: '100%' }}>
            <Button
              sx={{
                width: '100%',
                '& .MuiButton-startIcon': {
                  marginRight: 0, // Set margin-right to 0
                  marginLeft: 0, // Set margin-right to 0
                },
              }}
              size="small"
              variant={'outlined'}
              startIcon={<CalendarIcon />}
            ></Button>
            <Button
              sx={{
                width: '100%',
                '& .MuiButton-startIcon': {
                  marginRight: 0, // Set margin-right to 0
                  marginLeft: 0, // Set margin-right to 0
                },
              }}
              size="small"
              variant={'outlined'}
              startIcon={<PlayCircleOutline />}
            ></Button>
            <Button
              sx={{
                width: '100%',
                '& .MuiButton-startIcon': {
                  marginRight: 0, // Set margin-right to 0
                  marginLeft: 0, // Set margin-right to 0
                },
              }}
              size="small"
              variant={'outlined'}
              startIcon={<IconRoad />}
            ></Button>
            <Button
              sx={{
                width: '100%',
                '& .MuiButton-startIcon': {
                  marginRight: 0, // Set margin-right to 0
                  marginLeft: 0, // Set margin-right to 0
                },
              }}
              size="small"
              variant={'outlined'}
              startIcon={<InfoCircleOutlined />}
              onClick={() => console.log('Hello')}
            ></Button>
          </ButtonGroup>
        </Grid> */}

    </Card>
  );
},
);
const CreatePlayByInfowindow: React.FC<{ position: any; device: any; beginningPosition: any }> =
  React.memo(({ position, device, beginningPosition }) => {
    // const status = knotsToKmh(position.speed) > 0 ? 'Online' : 'Idle';
    // const color = knotsToKmh(position.speed) > 0 ? "#15e01f" : '#e9821b';

    return (
      <Card sx={{ flexGrow: 1, maxWidth: '300px!important' }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Vehicle</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {device.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Marked Time</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {formatDate(position.deviceTime)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Traveling Time</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {getTimeDifferenceText(beginningPosition.deviceTime, position.deviceTime)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Latitude</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {position.latitude}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Longitude</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {position.longitude}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  });
const CreatePointsInfowindow: React.FC<{ position: any; beginningPosition: any; address: any }> =
  React.memo(({ position, beginningPosition, address }) => {
    // const status = knotsToKmh(position.speed) > 0 ? 'Online' : 'Idle';
    // const color = knotsToKmh(position.speed) > 0 ? "#15e01f" : '#e9821b';
    console.log(beginningPosition);
    console.log(position);

    return (
      <Card sx={{ flexGrow: 1, maxWidth: '300px!important' }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Departure</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {beginningPosition
                ? formatDate(beginningPosition.deviceTime)
                : formatDate(position.deviceTime)}
            </Typography>
          </Grid>
          {beginningPosition && (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Arrival</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
                  {formatDate(position.deviceTime)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Travel Time</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
                  {getTimeDifferenceText(beginningPosition.deviceTime, position.deviceTime)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <Typography variant="subtitle2">Location</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
              {address}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  });
const CreateStopsInfowindow: React.FC<{ position: any, geofenceName: any }> = React.memo(({ position, geofenceName }) => {
  return (
    <Card sx={{ flexGrow: 1, maxWidth: '300px!important' }}>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Place</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {geofenceName}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Stopped</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {formatDate(position.startTime)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Until</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {formatDate(position.endTime)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">Total</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {getTimeDifferenceText(position.startTime, position.endTime)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
});
const CreateGeofenceMarker: React.FC<{ isCompetitor: any; isArea: any, geofenceDetails: any }> = React.memo(
  ({ isCompetitor, isArea, geofenceDetails }) => {

    return (
      <>
        <CardContent
          sx={{
            padding: 0,
            display: 'none',
            '&:last-child': {
              paddingBottom: 0,
            },
          }}
        >
          <Stack
            sx={{ padding: '3px 8px' }}
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems={'center'} spacing={2}>
              <img
                src={isArea ? AreaOfResponsibilityMarker : (isCompetitor ? CompetitorMarker : StationMarker)}
                alt=""
                height={30}
                width={30}
              />
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {geofenceDetails.name}
                </Typography>
                <Typography variant="caption" color="textSecondary" alignItems="center" noWrap>
                  {geofenceDetails.description ? geofenceDetails.description : 'No Description'}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </CardContent>

        <Box sx={{ textAlign: '-webkit-center' }}>
          <Avatar
            src={isArea ? AreaOfResponsibilityMarker : isCompetitor ? CompetitorMarker : StationMarker}
            alt=""
            sx={{ height: 30, width: 30, transform: `translate(0px, 10%)` }}
          />
        </Box>

        {/* <img style={{ transform: `translate(0px, 10%)` }} src={isCompetitor ? CompetitorMarker : StationMarker} alt="" height={30} width={30} /> */}
      </>
    );
  },
);
const CreateEnterAndExitInfowindow: React.FC<{ position: any, geofenceName: any, isEnter: boolean }> = React.memo(({ position, geofenceName, isEnter }) => {

  return (
    <Card sx={{ flexGrow: 1, maxWidth: '300px!important' }}>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            {isEnter ? "Arrival: " : "Departure: "}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {formatDate(position.deviceTime)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Type:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {isEnter ? "Geofence Enter" : "Geofence Exit"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Event Id:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {position.id}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Geofence:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle2" sx={{ ml: 1 }} flexGrow={1}>
            {geofenceName}
          </Typography>
        </Grid>
      </Grid>
    </Card>

  );
});

export {
  CreateMarkerWithChip,
  CreateVehicleInfowindow,
  CreatePlayByInfowindow,
  CreatePointsInfowindow,
  CreateStopsInfowindow,
  CreateGeofenceMarker,
  CreateEnterAndExitInfowindow
};
