import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { IconCheck, IconMenu2, IconPencil, IconTrash } from '@tabler/icons';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import PageContainer from 'src/components/container/PageContainer';
import Scrollbar from 'src/components/custom-scroll/Scrollbar';
import DashboardCard from 'src/components/shared/DashboardCard';
import CustomFormLabel from 'src/components/theme-elements/CustomFormLabel';
import CustomTextField from 'src/components/theme-elements/CustomTextField';
import {
  AreaOfResponsibilityMarker,
  CompetitorMarker,
  StationMarker,
} from 'src/globals/ImportMarkers';
import { CreateGeofenceMarker } from 'src/globals/InfoWindows';
import {
  libraries,
  mapContainerStyle,
  mapOption,
  mapCenter,
  darkModeMap,
  retroModeMap,
} from 'src/globals/MapSettings';
import CustomCheckbox from '../manage-system-users/CustomCheckbox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEssentialDataContext } from 'src/contexts/EssentialDataContext';
import useServer from 'src/utils/useServer';

type Props = {};

let geofenceMarker: any = [];
let drawingManager: any = null;
let drawnPolygon: any = null;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Geofence name is required'),
  description: Yup.string().required('Geofence description is required'),
});

function ManageGeofence({}: Props) {
  const { essentialData } = useEssentialDataContext();
  const { groups, devices, geofences, drivers } = essentialData;

  const server = useServer();
  const token = localStorage.getItem('token');

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [fromEdit, setFromEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadedGeofences, setLoadedGeofences] = useState<any>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [editId, setEditId] = useState(null);
  const [geoId, setGeoId] = useState(null);
  const [deleteGeoId, setDeleteGeoId] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const geoIdRef = useRef(geoId);
  const editIdRef = useRef(editId);

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      description: '',
      isCompetitor: false,
      isArea: false,
      polygon: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        const passValues = {
          id: values.id,
          name: values.name,
          description: values.description,
          area: values.polygon,
          attributes: {
            is_competitor: values.isCompetitor ? 1 : 0,
            is_area: 0,
            is_deleted: 0,
          },
        };
        if (fromEdit) {
          const response = await axios.put(
            `https://${server}/api/geofences/${values.id}`,
            passValues,
            { headers },
          );
        } else {
          const response = await axios.post(`https://${server}/api/geofences`, passValues, {
            headers,
          });
          if (response.status == 200) {
            const color = values.isCompetitor ? '#FF0000' : '#0070ff';

            const latLngPairs = values.polygon
              .replace('POLYGON ((', '')
              .replace('))', '')
              .split(', ')
              .map((pair) => pair.split(' ').map(parseFloat))
              .map(([lat, lng]) => ({ lat, lng }));
            const polygon = new google.maps.Polygon({
              paths: latLngPairs,
              strokeColor: color,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: color,
              fillOpacity: 0.35,
            });
            polygon.setMap(map);
            const bounds = new google.maps.LatLngBounds();
            polygon.getPath().forEach(function (path, index) {
              bounds.extend(path);
            });

            const marker = new google.maps.marker.AdvancedMarkerElement({
              zIndex: 5,
              map,
              position: new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng()),
              content: buildGeofenceMarker(values.isCompetitor, values.isArea, response.data),
            });

            addGeofenceCustomProp(
              marker,
              values.isCompetitor,
              values.isArea,
              response.data.id,
              polygon,
              response.data.description,
              response.data.name,
            );

            marker.addListener('click', () => {
              if (marker.content instanceof HTMLElement) {
                if (marker.content.classList.contains('infowindow-active')) {
                  marker.content.classList.remove('infowindow-active');
                } else {
                  marker.content.classList.add('infowindow-active');
                }
              }
            });
            geofenceMarker.push(marker);
            drawnPolygon.setMap(null);
            const updatedGeofences = [...loadedGeofences, response.data];
            setLoadedGeofences(updatedGeofences);
          }
        }
        setOpenDialog(false);
      } else {
        console.log('Form invalid');
      }
    },
  });
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCNgURjS60WS_tY5tdpjsTDGXdYMBv3fPA',
    libraries,
  });
  const onLoad = React.useCallback(function callback(map: any) {
    mapRef.current = map;

    const darkMapType = new google.maps.StyledMapType(darkModeMap, { name: 'Dark' });
    const retroMapType = new google.maps.StyledMapType(retroModeMap, { name: 'Retro' });
    map.mapTypes.set('dark', darkMapType);
    map.mapTypes.set('retro', retroMapType);
    map.setMapTypeId('retro');

    // const geocoder = new google.maps.Geocoder();

    // geocoder
    //     .geocode({
    //         placeId: "ChIJi_RPNjrJjjMRzYB5Jl1fMTI",
    //     })
    //     .then((result: google.maps.GeocoderResponse) => {
    //         console.log(result.results[0].geometry.location);
    //     });

    // const featureLayer = map.getFeatureLayer("ADMINISTRATIVE_AREA_LEVEL_1");

    // const featureStyleOptions = {
    //     strokeColor: "#810FCB",
    //     strokeOpacity: 1.0,
    //     strokeWeight: 3.0,
    //     fillColor: "#810FCB",
    //     fillOpacity: 0.5,
    // };

    // console.log(featureLayer);
    // featureLayer.style = (options: any) => {
    //     const placeId = options.feature.placeId;
    //     if (Object.values(arrayOfPlaceId).includes(placeId)) {
    //         return featureStyleOptions;
    //     }
    // };

    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  useEffect(() => {
    if (geofences.length > 0) {
      setDataLoaded(true);
    }
  }, [geofences]);
  useEffect(() => {
    if (dataLoaded && map) {
      geofenceMarker = geofences.map((geofences) => {
        const color = geofences.attributes.is_area
          ? '#17fcf0'
          : geofences.attributes.is_competitor
          ? '#FF0000'
          : '#0070ff';

        const latLngPairs = geofences.area
          .replace('POLYGON ((', '')
          .replace('))', '')
          .split(', ')
          .map((pair) => pair.split(' ').map(parseFloat))
          .map(([lat, lng]) => ({ lat, lng }));

        const polygon = new google.maps.Polygon({
          paths: latLngPairs,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
        });
        polygon.setMap(map);
        const bounds = new google.maps.LatLngBounds();
        polygon.getPath().forEach(function (path, index) {
          bounds.extend(path);
        });

        const marker = new google.maps.marker.AdvancedMarkerElement({
          zIndex: 5,
          map,
          position: new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng()),
          content: buildGeofenceMarker(
            geofences.attributes.is_competitor == 1 ? true : false,
            geofences.attributes.is_area == 1 ? true : false,
            geofences,
          ),
        });

        addGeofenceCustomProp(
          marker,
          geofences.attributes.is_competitor == 1 ? true : false,
          geofences.attributes.is_area == 1 ? true : false,
          geofences.id,
          polygon,
          geofences.description,
          geofences.name,
        );

        marker.addListener('click', () => {
          if (marker.content instanceof HTMLElement) {
            if (marker.content.classList.contains('infowindow-active')) {
              marker.content.classList.remove('infowindow-active');
            } else {
              marker.content.classList.add('infowindow-active');
            }
          }
        });

        return marker;
      });

      setLoadedGeofences(geofences);
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
      });
      drawingManager.setMap(map);

      google.maps.event.addListener(drawingManager, 'overlaycomplete', function (event: any) {
        handleClickOpenDialog();
        const path = event.overlay.getPath();
        drawnPolygon = event.overlay;
        let polygonWKT = 'POLYGON ((';
        path.forEach((latLng: any, index: any) => {
          polygonWKT += `${latLng.lat()} ${latLng.lng()}`;
          if (index < path.getLength() - 1) {
            polygonWKT += ', ';
          }
        });
        polygonWKT += '))';
        drawingManager.setDrawingMode(null);

        formik.setFieldValue('polygon', polygonWKT);
        setFromEdit(false);
      });
    }
  }, [dataLoaded, map]);
  const handleGeofenceClick = React.useCallback((passedGeofence: any, isSaving: boolean) => {
    const index = geofenceMarker.findIndex(
      (geofence: any) => geofence.geofenceId == passedGeofence.id,
    );
    const geoMarker = geofenceMarker[index];

    if (geoIdRef.current && isSaving) {
      setFromEdit(true);
      setFormikValue(geoMarker);
      geoMarker.polygon.setEditable(false);
    } else {
      if (editIdRef.current) {
        if (geoIdRef.current != passedGeofence.id) {
          console.log('You have unsaved edited geofence');
        }
      } else {
        console.log('Setted an new geoId');
      }
    }

    if (!isSaving && index != -1) {
      geoMarker.polygon.setEditable(true);
      if (mapRef.current) {
        mapRef.current.setCenter({ lat: geoMarker.position.lat, lng: geoMarker.position.lng });
        mapRef.current.setZoom(17);
      }
    }

    geoIdRef.current = passedGeofence.id;
    editIdRef.current = passedGeofence.id;
    setEditId(passedGeofence.id);
    setGeoId(passedGeofence.id);
  }, []);
  const handleGeofenceDelete = React.useCallback((passedGeofence: any) => {
    console.log(passedGeofence);
    setDeleteGeoId(passedGeofence.id);
    setOpenDeleteDialog(true);
  }, []);

  const toggleDrawer = (openStatus: any) => () => {
    setOpen(openStatus);
  };
  const zoomChanged = () => {
    console.log('zoom changed');
  };
  const buildGeofenceMarker = (
    value: boolean,
    area: boolean,
    geofenceDetails: any,
  ): HTMLElement => {
    const GeofenceMarker = document.createElement('div');
    const GeofencerMarkerRoot = createRoot(GeofenceMarker);

    GeofencerMarkerRoot.render(
      <CreateGeofenceMarker isCompetitor={value} isArea={area} geofenceDetails={geofenceDetails} />,
    );

    return GeofenceMarker;
  };
  const addGeofenceCustomProp = (
    marker: any,
    isCompetitor: any,
    isArea: any,
    geofenceId: any,
    polygon: any,
    description: any,
    geofenceName: any,
  ) => {
    marker.isCompetitor = isCompetitor;
    marker.isArea = isArea;
    marker.geofenceId = geofenceId;
    marker.polygon = polygon;
    marker.description = description;
    marker.geofenceName = geofenceName;

    // markerArrays.push(marker);
  };
  const setFormikValue = (geoMarker: any) => {
    let polygonWKT = 'POLYGON ((';
    geoMarker.polygon.getPath().forEach((latLng: any, index: any) => {
      polygonWKT += `${latLng.lat()} ${latLng.lng()}`;
      if (index < geoMarker.polygon.getPath().getLength() - 1) {
        polygonWKT += ', ';
      }
    });
    polygonWKT += '))';

    formik.setFieldValue('id', geoMarker.geofenceId);
    formik.setFieldValue('name', geoMarker.geofenceName);
    formik.setFieldValue('isCompetitor', geoMarker.isCompetitor == 1 ? true : false);
    formik.setFieldValue(
      'description',
      geoMarker.description ? geoMarker.description : 'No Description',
    );
    formik.setFieldValue('polygon', polygonWKT);
    handleClickOpenDialog();
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    if (drawnPolygon) {
      drawnPolygon.setMap(null);
    }
    setOpenDialog(false);
  };
  const handleDeleteGeofence = async () => {
    const geofenceIndex = geofenceMarker.findIndex(
      (geofence: any) => geofence.geofenceId == deleteGeoId,
    );
    if (geofenceIndex != -1) {
      let polygonWKT = 'POLYGON ((';
      geofenceMarker[geofenceIndex].polygon.getPath().forEach((latLng: any, index: any) => {
        polygonWKT += `${latLng.lat()} ${latLng.lng()}`;
        if (index < geofenceMarker[geofenceIndex].polygon.getPath().getLength() - 1) {
          polygonWKT += ', ';
        }
      });
      polygonWKT += '))';
      const passValues = {
        id: geofenceMarker[geofenceIndex].geofenceId,
        name: geofenceMarker[geofenceIndex].geofenceName,
        description: geofenceMarker[geofenceIndex].description,
        area: polygonWKT,
        attributes: {
          is_competitor: geofenceMarker[geofenceIndex].isCompetitor ? 1 : 0,
          is_area: geofenceMarker[geofenceIndex].isArea ? 1 : 0,
          is_deleted: 1,
        },
      };
      const response = await axios.put(
        `https://${server}/api/geofences/${geofenceMarker[geofenceIndex].geofenceId}`,
        passValues,
        { headers },
      );
      if (response.status == 200) {
        geofenceMarker[geofenceIndex].polygon.setMap(null);
        geofenceMarker[geofenceIndex].setMap(null);
        const updatedGeofences = loadedGeofences.filter(
          (geofence: any) => geofence.id !== geofenceMarker[geofenceIndex].geofenceId,
        );
        setLoadedGeofences(updatedGeofences);
        setOpenDeleteDialog(false);
      }
    }
  };
  const handleDeletedialogClose = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <PageContainer title="RKCGPS - Manage Geofence" description="this is Modern Dashboard page">
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ mt: 5 }}>
        <Grid item xs={4} sm={8} md={3} display={{ xs: 'none', md: 'block' }}>
          <DashboardCard title="Geofence List" height="100%">
            <Box sx={{ height: '100%' }}>
              <Scrollbar sx={{ height: '71vh' }}>
                <Stack spacing={0}>
                  {loadedGeofences.length == 0 && (
                    <List>
                      {Array.from({ length: 10 }, (_, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <Skeleton variant="rectangular" width={20} height={20} />
                          </ListItemIcon>
                          <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} height={35} />
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            sx={{ ml: 2 }}
                          >
                            <Skeleton variant="rectangular" width={19} height={19} />
                            <Skeleton variant="rectangular" sx={{ ml: 1 }} width={19} height={19} />
                          </ButtonGroup>
                        </ListItem>
                      ))}
                    </List>
                  )}

                  <List>
                    {loadedGeofences
                      .filter((geofence: any) => geofence.attributes.is_area != 1)
                      .map((geofence: any) => (
                        <GeofenceListComponent
                          key={geofence.id}
                          geofence={geofence}
                          onDeviceClick={handleGeofenceClick}
                          handleGeofenceDelete={handleGeofenceDelete}
                        />
                      ))}
                  </List>
                  {/* {geofences.map(geofence => (
                                        <GeofenceComponent key={device.id} device={device} map={map} onDeviceClick={handleDeviceClick} />
                                    ))} */}
                </Stack>
              </Scrollbar>
            </Box>
          </DashboardCard>
        </Grid>
        <Grid item xs={4} sm={8} md={9}>
          <Box style={{ width: '100%', position: 'relative' }} height={{ xs: '80vh', md: '100%' }}>
            <Box
              display={{ xs: 'block', md: 'none' }}
              style={{
                zIndex: 2,
                position: 'absolute',
                top: '10px',
                left: '10px',
              }}
            >
              <Tooltip title="Vehicle Menu">
                <Button
                  aria-label="Vehicle Menu"
                  onClick={toggleDrawer(true)}
                  sx={{ p: 1, minWidth: 0 }}
                >
                  <IconMenu2 />
                </Button>
              </Tooltip>
            </Box>

            {isLoaded && (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                onZoomChanged={zoomChanged}
                zoom={10}
                onLoad={onLoad}
                id="map"
                onUnmount={onUnmount}
                options={mapOption}
              ></GoogleMap>
            )}
            <Drawer
              sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-root': {
                  position: 'absolute',
                },
                '& .MuiPaper-root': {
                  width: 340,
                  overflowX: 'hidden',
                  position: 'absolute',
                },
              }}
              variant="persistent"
              anchor="left" // Specify the side where the drawer will be displayed (left, right, top, bottom)
              open={open}
              onClose={toggleDrawer(false)}
            >
              <Card>
                <CardContent sx={{ mb: 0, padding: '0 !important' }}>
                  <Typography variant="h5">Geofence List</Typography>
                </CardContent>
                <IconButton
                  aria-label="close"
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={toggleDrawer(false)}
                >
                  <Close />
                </IconButton>
                <Stack spacing={0} sx={{ maxHeight: '619px', overflowX: 'auto' }}>
                  {loadedGeofences.length == 0 && (
                    <List>
                      {Array.from({ length: 10 }, (_, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <Skeleton variant="rectangular" width={20} height={20} />
                          </ListItemIcon>
                          <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} height={35} />
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            sx={{ ml: 2 }}
                          >
                            <Skeleton variant="rectangular" width={19} height={19} />
                            <Skeleton variant="rectangular" sx={{ ml: 1 }} width={19} height={19} />
                          </ButtonGroup>
                        </ListItem>
                      ))}
                    </List>
                  )}

                  <List>
                    {loadedGeofences
                      .filter((geofence: any) => geofence.attributes.is_area != 1)
                      .map((geofence: any) => (
                        <GeofenceListComponent
                          key={geofence.id}
                          geofence={geofence}
                          onDeviceClick={handleGeofenceClick}
                          handleGeofenceDelete={handleGeofenceDelete}
                        />
                      ))}
                  </List>
                </Stack>
              </Card>
            </Drawer>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth={'md'} fullWidth={true}>
        <DialogTitle>{fromEdit ? 'Edit' : 'Add'} Geofence</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText>Please provide geofence name and description</DialogContentText>
            <Box mt={2}>
              <CustomFormLabel
                sx={{
                  mt: 0,
                }}
                htmlFor="name"
              >
                Geofence Name
              </CustomFormLabel>
              <CustomTextField
                id="name"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
              <CustomFormLabel
                sx={{
                  mt: 0,
                }}
                htmlFor="description"
              >
                Geofence Description
              </CustomFormLabel>
              <CustomTextField
                id="description"
                variant="outlined"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                fullWidth
              />

              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={formik.values.isCompetitor}
                    onChange={(event) => {
                      formik.setFieldValue('isCompetitor', event.target.checked ? 1 : 0);
                    }}
                    name="isCompetitor"
                    color="primary"
                  />
                }
                label="Is Competitor"
                sx={{
                  mb: 1,
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeletedialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {openDeleteDialog &&
              (() => {
                console.log(geofenceMarker);

                const index = geofenceMarker.findIndex(
                  (geofence: any) => geofence.geofenceId == deleteGeoId,
                );

                return (
                  index !== -1 &&
                  `Are you sure you want to delete ${geofenceMarker[index].geofenceName}?`
                );
              })()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDeletedialogClose}>
            Cancel
          </Button>
          <Button onClick={handleDeleteGeofence} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
}
const GeofenceListComponent: React.FC<{
  geofence: any;
  onDeviceClick: any;
  handleGeofenceDelete: any;
}> = React.memo(({ geofence, onDeviceClick, handleGeofenceDelete }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (geofenceDetails: any) => {
    if (geofenceDetails.id && !isEditing) {
      setIsEditing(true);
      onDeviceClick(geofenceDetails, false);
    } else {
      onDeviceClick(geofenceDetails, true);
      setIsEditing(false);
    }
  };
  const handleDeleteClick = (geofenceDetails: any) => {
    handleGeofenceDelete(geofenceDetails);
  };

  return (
    <ListItem
      id={geofence.id}
      sx={{
        '& .MuiTypography-body1': {
          fontSize: { xs: '8pt', md: '10pt' },
          lineHeight: { xs: '1rem', md: '1.334rem' },
        },
      }}
    >
      <ListItemIcon>
        <img
          src={
            geofence.attributes.is_area == 1
              ? AreaOfResponsibilityMarker
              : geofence.attributes.is_competitor == 1
              ? CompetitorMarker
              : StationMarker
          }
          alt=""
          height={20}
          width={20}
        />
      </ListItemIcon>
      <ListItemText id="switch-list-label-wifi" primary={geofence.name} />
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          sx={{
            '& .MuiButton-startIcon': {
              marginRight: 0, // Set margin-right to 0
              marginLeft: 0, // Set margin-right to 0
            },
          }}
          size="small"
          color="success"
          variant={'outlined'}
          startIcon={isEditing ? <IconCheck /> : <IconPencil />}
          onClick={() => handleEditClick(geofence)}
        ></Button>
        <Button
          sx={{
            '& .MuiButton-startIcon': {
              marginRight: 0, // Set margin-right to 0
              marginLeft: 0, // Set margin-right to 0
            },
          }}
          size="small"
          color="error"
          variant={'outlined'}
          startIcon={<IconTrash />}
          onClick={() => handleDeleteClick(geofence)}
        ></Button>
      </ButtonGroup>
    </ListItem>
  );
});
export default ManageGeofence;
