import React, { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes, Navigate } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import { EssentialDataProvider, useEssentialDataContext } from './contexts/EssentialDataContext';
import { AuthProvider } from './contexts/AuthProvider';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { TransactionIdProvider } from './contexts/TransactionProvider';
import { NotificationSettingsProvider } from './contexts/NotificationSettingContext';

interface RouteObject {
  path: string;
  element: JSX.Element;
  uac: string[]; // Define uac property
  children?: RouteObject[]; // Make children property optional
}

const App: FC = () => {
  return (
    <AuthProvider>
      <EssentialDataProvider>
        <WebSocketProvider>
          <TransactionIdProvider>
            <NotificationSettingsProvider>
              <AppContent />
            </NotificationSettingsProvider>
          </TransactionIdProvider>
        </WebSocketProvider>
      </EssentialDataProvider>
    </AuthProvider>
  );
};

const AppContent: FC = () => {
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);
  const { userData } = useEssentialDataContext();

  // console.log('App userData: ', userData);

  const filterRoutes = (routes: RouteObject[]): RouteObject[] => {
    const userLevel = userData?.attributes.userLevel;

    return routes.map((route) => {
      // Filter children if exist
      let children: RouteObject[] | undefined = undefined;
      if (route.children) {
        children = filterRoutes(route.children);
      }

      // Check accessibility
      const isAccessible =
        route.uac.length === 0 ||
        (userLevel === 'user' && route.uac.includes('')) ||
        (userLevel === 'semi-admin' && route.uac.includes('semi-admin')) ||
        userLevel === 'admin';

      // Modify inaccessible routes to show forbidden error
      if (!isAccessible) {
        return { ...route, element: <Navigate to="/error/403" />, children };
      }

      return { ...route, children };
    });
  };

  // Apply filtering
  const filteredRoutes = filterRoutes(Router);

  const routing = useRoutes(filteredRoutes);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
