import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useAuth } from './AuthProvider';
import useServer from 'src/utils/useServer';

interface Geofence {
  id: number;
  attributes: {
    is_competitor: number;
    is_deleted: number;
    is_area: number;
  };
  calendarId: number;
  name: string;
  description: string;
  area: string;
  latitude?: number | undefined;
  longitude?: number | undefined;
}

interface Devices {
  attributes: any;
  calendarId: number;
  contact: any;
  disabled: boolean;
  expirationTime: any;
  groupId: number;
  id: number;
  model: any;
  name: string;
  phone: any;
  driver: any;
  positionId: number;
  speed: number;
  latitude: number;
  longitude: number;
  course: number | null;
  status: string;
  map: any;
  geofenceIds: any;
  uniqueId: string;
  lastUpdate: string;
  timers: any;
  positionid: number;
  category: string;
  infowindowOpen: any;
}

interface Groups {
  id: number;
  attributes: {
    speedLimit: number;
  };
  groupId: number;
  name: string;
}

interface Drivers {
  id: number;
  attributes: {
    truck_device: number;
    driver_phone: number;
  };
  unique_id: string;
  name: string;
}

interface Notifications {
  id: number;
  attributes: any;
  calendarId: number;
  always: boolean;
  type: string;
  commandId: number;
  notificators: string;
}
interface Maintenance {
  id: number;
  attributes: any;
  name: string;
  type: string;
  start: number;
  period: number;
}

interface EssentialData {
  geofences: Geofence[];
  groups: Groups[];
  drivers: Drivers[];
  devices: Devices[];
  notifications: Notifications[];
  maintenance: Maintenance[];
}

interface UserData {
  id: number;
  attributes: {
    userLevel: string;
  };
  name: string;
  email: string;
}

interface EssentialDataContextType {
  essentialData: EssentialData;
  refreshData: () => void;
  userData: UserData | null;
}

interface Props {
  children: ReactNode;
}

const EssentialDataContext = createContext<EssentialDataContextType | undefined>(undefined);

export const useEssentialDataContext = () => {
  const context = useContext(EssentialDataContext);
  if (!context) {
    throw new Error('useEssentialDataContext must be used within a EssentialDataProvider');
  }

  return context;
};

const token = localStorage.getItem('token');

const EssentialDataProvider: React.FC<Props> = ({ children }: Props) => {
  const [userData, setUserData] = useState<UserData | null>(null);


  const { isAuthenticated } = useAuth();

  useEffect(() => {

    if (isAuthenticated) {
      fetchData();
      const localUserData = localStorage.getItem('userDetails');
      setUserData(localUserData ? JSON.parse(localUserData) : null);
    }
  }, [isAuthenticated]);

  //console.log('Authentication State: ', isAuthenticated);

  const calculatePolygonCenter = (polygonPaths: any) => {
    const totalPoints = polygonPaths.length;
    const center = polygonPaths.reduce(
      (acc: any, curr: any) => {
        return {
          lat: acc.lat + curr.lat / totalPoints,
          lng: acc.lng + curr.lng / totalPoints,
        };
      },
      { lat: 0, lng: 0 },
    );

    return center;
  };

  const [essentialData, setEssentialData] = useState<EssentialData>({
    geofences: [],
    groups: [],
    drivers: [],
    devices: [],
    notifications: [],
    maintenance: [],
  });

  const SERVER = useServer();

  const token = localStorage.getItem('token');

  const fetchData = async () => {
    const urls = [
      `https://${SERVER}/api/geofences?all=true`,
      `https://${SERVER}/api/groups`,
      `https://${SERVER}/api/drivers`,
      `https://${SERVER}/api/devices`,
      `https://${SERVER}/api/notifications`,
      `https://${SERVER}/api/maintenance`,
    ];

    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const responses = await Promise.all(urls.map((url) => fetch(url, options)));
      const data = await Promise.all(responses.map((response) => response.json()));

      data[2].map((response: any) => {
        const deviceIndex = data[3].findIndex(
          (device: any) => device.id == response.attributes.truck_device,
        );
        if (deviceIndex !== -1) {
          data[3][deviceIndex].driverName = response.name;
        } else {
          // Fix this part
          // data[3][deviceIndex].driverName = 'No driver';
        }
      });

      console.log('Response: ', data);
      const processedGeofences = data[0].filter((option: any) => option.attributes.is_deleted !== 1).map((geofence: Geofence) => {
        const paths = geofence.area.split(',').map((coords) => {
          const [lat, lng] = coords
            .trim()
            .replace('POLYGON ((', '')
            .replace('))', '')
            .split(' ')
            .map((coord) => parseFloat(coord));

          return { lat, lng };
        });

        const center = calculatePolygonCenter(paths);

        return {
          ...geofence,
          latitude: center.lat,
          longitude: center.lng,
        };
      });

      setEssentialData({
        geofences: processedGeofences,
        groups: data[1],
        drivers: data[2],
        devices: data[3],
        notifications: data[4],
        maintenance: data[5],
      });
    } catch (error) {
      console.error('Error fetching essential data:', error);
    }
  };

  // Function to refresh data
  const refreshData = () => {
    fetchData();
  };

  return (
    <EssentialDataContext.Provider value={{ essentialData, refreshData, userData }}>
      {children}
    </EssentialDataContext.Provider>
  );
};

export { EssentialDataProvider };
