import React, { createContext, useState, useContext } from 'react';

interface NotificationSettingsContextType {
  isMuted: boolean;
  setIsMuted: (muted: boolean) => void;
}

const NotificationSettingsContext = createContext<NotificationSettingsContextType | undefined>(
  undefined,
);

export const NotificationSettingsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isMuted, setIsMuted] = useState(false);

  console.log('Mute Status', isMuted);

  return (
    <NotificationSettingsContext.Provider value={{ isMuted, setIsMuted }}>
      {children}
    </NotificationSettingsContext.Provider>
  );
};

export const useNotificationSettings = () => {
  const context = useContext(NotificationSettingsContext);
  if (context === undefined) {
    throw new Error('useNotificationSettings must be used within a NotificationSettingsProvider');
  }

  return context;
};
