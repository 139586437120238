import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import Sms from './Sms/Sms';
import Numbers from './Numbers/Numbers';

import SystemSetup from './SystemSetup/SystemSetup';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Manage System Settings',
  },
];

const System = () => {
  return (
    <PageContainer
      title="RKCGPS - System Settings"
      description="this is where you manage the system settings"
    >
      <Breadcrumb title="System Settings" items={BCrumb} />
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <Sms />
          </Grid>

          <Grid item xs={12} sm={6} lg={8}>
            <Numbers />
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <SystemSetup />
          </Grid>

        </Grid>
      </Box>
    </PageContainer>
  );
};

export default System;
