import React, { lazy, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import TripsV2 from 'src/views/trips/Trips';
import ManageGeofenceV2 from 'src/views/manage-geofences/ManageGeofence';
import System from 'src/views/system/System';
import ManageGeofence from 'src/views/manage-geofences/ManageGeofence';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const FreeLayout = Loadable(lazy(() => import('../layouts/free/FreeLayout')));

/* ****Pages***** */
const Home = Loadable(lazy(() => import('../views/home/Home')));
const Forbidden = Loadable(lazy(() => import('../views/errors/Forbidden')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Navigator = Loadable(lazy(() => import('../views/navigator/Navigator')));
const ManageDevices = Loadable(lazy(() => import('../views/manage-devices/ManageDevices')));
const ManageDrivers = Loadable(lazy(() => import('../views/manage-drivers/ManageDrivers')));
const ManageGeofences = Loadable(lazy(() => import('../views/manage-geofences/ManageGeofence')));
const ManageGroups = Loadable(lazy(() => import('../views/manage-groups/Groups')));
const VehicleMaintenance = Loadable(
  lazy(() => import('../views/manage-vehicle-maintenance/VehicleMaintenance')),
);
const SystemUsers = Loadable(lazy(() => import('../views/manage-system-users/SystemUsers')));
const Trips = Loadable(lazy(() => import('../views/trips/Trips')));
const Replays = Loadable(lazy(() => import('../views/replays/Replays')));
const Events = Loadable(lazy(() => import('../views/events/Events')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Logout = Loadable(lazy(() => import('../views/authentication/Logout')));

interface RouteObject {
  path: string;
  element: JSX.Element;
  uac: string[]; // Define uac property
  children?: RouteObject[]; // Make children property optional
}

const Router: RouteObject[] = [
  {
    path: '/',
    element: <FullLayout />,
    uac: [], // Set uac for root route
    children: [
      { path: '/', element: <Navigate to="/home" />, uac: [] },
      { path: '/home', element: <Home />, uac: [] },
      { path: '/navigator', element: <Navigator />, uac: [] },
      { path: '/ManageDevices', element: <ManageDevices />, uac: ['admin'] },
      { path: '/ManageDrivers', element: <ManageDrivers />, uac: ['admin', 'semi-admin'] },
      { path: '/ManageGeofences', element: <ManageGeofence />, uac: ['admin', 'semi-admin'] },
      {
        path: '/VehicleMaintenance',
        element: <VehicleMaintenance />,
        uac: ['admin', 'semi-admin'],
      },
      { path: '/Groups', element: <ManageGroups />, uac: ['admin'] },
      { path: '/SystemUsers', element: <SystemUsers />, uac: ['admin'] },
      { path: '/Events', element: <Events />, uac: [] },
      { path: '/Trips', element: <Trips />, uac: [] },
      { path: '/Trips/:id', element: <Trips />, uac: [] },
      { path: '/Replays', element: <Replays />, uac: [] },
      { path: '/Replays/:id', element: <Replays />, uac: [] },
      { path: '/System', element: <System />, uac: ['admin'] },
      { path: '*', element: <Navigate to="/error/404" />, uac: [] },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    uac: [], // Set uac for auth route
    children: [
      { path: 'login', element: <Login />, uac: [] },
      { path: 'logout', element: <Logout />, uac: [] },
      { path: '*', element: <Navigate to="/error/404" />, uac: [] },
    ],
  },
  {
    path: '/error',
    element: <FreeLayout />,
    uac: [], // Set uac for error route
    children: [
      { path: '404', element: <Error />, uac: [] },
      { path: '403', element: <Forbidden />, uac: [] },
    ],
  },
];

export default Router;
