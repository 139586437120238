import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Avatar, Fab, Box } from '@mui/material';
import {
  IconArrowDownRight,
  IconCurrencyDollar,
  IconPlayerPause,
  IconPlayerPlay,
  IconRotate,
  IconRotate2,
  IconRotate360,
} from '@tabler/icons';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Props } from 'react-apexcharts';
import { LoadingButton } from '@mui/lab';
import useServer from 'src/utils/useServer';

const Sms = () => {
  // chart color
  const TOKEN = localStorage.getItem('token') || '';
  const theme = useTheme();
  const secondary = theme.palette.secondary.main;
  const secondarylight = theme.palette.secondary.light;
  const errorlight = theme.palette.error.light;

  // chart options
  const optionscolumnchart: Props = {
    chart: {
      type: 'area',
      fontFamily: "'Plus Jakarta Sans', sans-serif;",
      foreColor: '#adb0bb',
      toolbar: {
        show: false,
      },
      height: 60,
      sparkline: {
        enabled: true,
      },
      group: 'sparklines',
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      colors: [secondarylight],
      type: 'solid',
      opacity: 0.05,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
      x: {
        show: false,
      },
    },
  };

  const [seriescolumnchart, setSeriescolumnchart] = useState([
    {
      name: '',
      color: secondary,
      data: [25, 66, 20, 40, 12, 58, 20],
    },
  ]);

  const [isRestartingGateway, setIsRestartingGateway] = useState(false);
  const [lastRestartPercentage, setLastRestartPercentage] = useState(0);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [lastRestartedData, setLastRestartedData] = useState(0);
  const [currentMessagesSent, setCurrentMessagesSent] = useState(0);

  const SERVER = useServer();
  let sms_node_server = process.env.REACT_APP_SMSGATEWAY;
  if(SERVER !== 'v2.rkcgps.net'){
    sms_node_server = process.env.REACT_APP_SMSGATEWAY_BACKUP;
  }

  const handleRestartWebsocket = async () => {
    setIsRestartingGateway(true);
    try {
      const response = await fetch(`https://${sms_node_server}/restart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN,
        },
      });
      if (response.ok) {
        console.log('WebSocket restarted successfully');
      } else {
        console.error('Failed to restart WebSocket');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsRestartingGateway(false);
    }
  };

  const handleToggleWebsocket = () => {
    if (isConnected) {
      ws?.close();
    } else {
      connectToWebsocket();
    }
  };

  const connectToWebsocket = () => {
    setIsConnecting(true);
    const websocket = new WebSocket(`wss://${sms_node_server}/ws`);

    websocket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnecting(false);
      setIsConnected(true);
    };

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Message from WebSocket:', message);
      setSeriescolumnchart((prevSeries) => [
        {
          ...prevSeries[0],
          data: [...prevSeries[0].data, message.success_counter].slice(-10),
        },
      ]);
      setCurrentMessagesSent(message.messages_sent_counter);
      setLastRestartedData(message.messages_old_counter);
    };

    websocket.onclose = () => {
      setIsConnected(false);
      console.log('WebSocket connection closed');
    };

    websocket.onerror = (error) => {
      console.error('WebSocket error:', error);
      setIsConnected(false);
      setIsConnecting(false);
    };

    setWs(websocket);
  };

  useEffect(() => {
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  return (
    <DashboardCard
      title="Sms Gateway"
      action={
        <Box>
          {isRestartingGateway ? (
            <LoadingButton
              loading
              variant="contained"
              color="primary"
              endIcon={<IconRotate width={18} />}
              size="small"
            >
              Restarting...
            </LoadingButton>
          ) : (
            <Fab color="secondary" size="small" onClick={handleRestartWebsocket}>
              <IconRotate width={24} />
            </Fab>
          )}
          <Fab color="secondary" size="small" onClick={handleToggleWebsocket}>
            {isConnected ? <IconPlayerPause width={24} /> : <IconPlayerPlay width={24} />}
          </Fab>
        </Box>
      }
      footer={
        <Chart options={optionscolumnchart} series={seriescolumnchart} type="area" height="190px" />
      }
    >
      <>
        <Typography variant="h3" fontWeight="700" mt="-20px">
          {currentMessagesSent}
        </Typography>
        <Typography variant="caption" fontWeight="200" mt="-20px">
          messages sent
        </Typography>
        <Stack direction="row" spacing={1} my={1} alignItems="center">
          <Avatar sx={{ bgcolor: errorlight, width: 27, height: 27 }}>
            <IconRotate2 width={20} color="#FA896B" />
          </Avatar>
          <Typography variant="subtitle2" fontWeight="600">
            {lastRestartedData}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            from last restart.
          </Typography>
        </Stack>
      </>
    </DashboardCard>
  );
};

export default Sms;
