import { useMemo } from 'react';

const useServer = () => {
  const SERVER_PRIMARY = process.env.REACT_APP_SERVER;
  const SERVER_BACKUP = process.env.REACT_APP_SERVER_BACKUP;

  return useMemo(() => {
    const hostname = window.location.hostname;
    console.log(hostname);

    if (process.env.NODE_ENV === 'production') {
      if (hostname === 'rkcgps.net') {
        console.log('Rkcgps host');

        return SERVER_PRIMARY;
      } else if (hostname === 'www.rkcgps.net') {
        console.log('Rkcgps host');

        return SERVER_PRIMARY;
      } else if (hostname === 'isp2.rkcgps.net') {
        console.log('ISP2 host');

        return SERVER_BACKUP;
      }
    } else {
      
      return SERVER_PRIMARY;
    }

    return null;
  }, [SERVER_PRIMARY, SERVER_BACKUP]);
};

export default useServer;
